import React from 'react'

import Layout from '../components/Layout/'
import SEO from '../components/Seo'
import SocialLinks from '../components/SocialLinks'

import { MainContent } from '../styles/base'

const AboutPage = () => (
  <Layout>
    <SEO
      title="About"
      description=""
    />
    <MainContent>
      <h1>About</h1>
      <p>Hi! My name is Augusto Bennemann (<a href="http://my.pronoun.is/he/him/his">he/him/his</a>). I'm a Full-Stack Web Developer with 6 years of experience. I hold a BSc (2018) degree in Computer Science from UFRGS.</p>
      <div style={{width: '200px', float:'right'}}><SocialLinks /></div>
    </MainContent>
  </Layout>
)

export default AboutPage
